/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-26 11:33:11
 * @FilePath: /mall-front-static/src/api/pay/index.ts
 * @Description:
 *
 */
import request from '@/utils/request';
import { IRequestResult } from '../type/tool';
import { PayTypeEnum } from '../type/base';
/* 创建订单时使用 */
export interface CreateOrderSkuItem {
  // 规格ID
  skuId: number;
  // 数量
  num: number;
}

export function createOrder(data: {
  skuList: CreateOrderSkuItem[];
  /* 支付类型 */
  payType: PayTypeEnum;
  /* 订单备注 */
  orderRemark?: string;
  /* 收货地址id */
  addressId?: string;
  /* 充值账号 */
  rechargeAccount?: string;
  /* 优惠券卡号卡密 */
  orderCoupon?: {
    couponNumber: string;
    couponPassword?: string;
  };
}): Promise<
  IRequestResult<{
    /* 预支付订单号 */
    prepayId?: string;
  }>
> {
  return request.post('/order/order_record/create_order', data, { hideMsg: true, loading: true, loadingText: '' });
}

/* 通过预支付订单号获取微信订单标识 */
export function getWechatPrepay(data: string): Promise<
  IRequestResult<{
    appId: string;
    nonceStr: string;
    package: string;
    paySign: string;
    signType: string;
    timeStamp: string;
  }>
> {
  return request.post(`/order/prepay/onPay/${data}`, {}, { hideMsg: true, loading: true, loadingText: '' });
}
