import { DeliverStateEnum, OrderStateEnum, OrderPayStateEnum } from '@/api/type/base';

export enum BaseOrderStateFnKey {
  /** 待支付 */
  PAYMENT_WAITING = 'PAYMENT_WAITING',
  /** 已取消 */
  ORDER_CANCEL = 'ORDER_CANCEL',
  /** 待发货 */
  DELIVER_WAITING = 'DELIVER_WAITING',
  /** 发货中 */
  DELIVER_IN = 'DELIVER_IN',
  /** 已发货 */
  DELIVER_TRANSIT = 'DELIVER_TRANSIT',
  /** 订单完成 已确认收货 */
  DELIVER_COMPLETE = 'DELIVER_COMPLETE',
  /** 退款中 售后中 */
  AFTER_SALES = 'AFTER_SALES',
  /** 退款完成 售后完成 */
  AFTER_SALES_COMPLETE = 'AFTER_SALES_COMPLETE',
  /** 订单关闭 售后完成 */
  ORDER_CLOSE = 'ORDER_CLOSE'
}

export default function (data: { payState: OrderPayStateEnum; orderState: OrderStateEnum; deliverState: DeliverStateEnum }): BaseOrderStateFnKey {
  const { payState, orderState, deliverState } = data;
  /* 已下单 */
  if (orderState === OrderStateEnum.ORDER_STATE_CREATE) {
    if (payState === OrderPayStateEnum.PAY_STATE_WAIT && deliverState === DeliverStateEnum.DELIVER_STATE_WAIT) {
      /* 代付款 */
      return BaseOrderStateFnKey.PAYMENT_WAITING;
    }
  } else if (orderState === OrderStateEnum.ORDER_STATE_CANCEL) {
    /* 已取消 */
    return BaseOrderStateFnKey.ORDER_CANCEL;
  } else if (orderState === OrderStateEnum.ORDER_STATE_FINISH) {
    /* 完成 */
    if (payState === OrderPayStateEnum.PAY_STATE_PAID && deliverState === DeliverStateEnum.DELIVER_STATE_WAIT) {
      /* 代发货 */
      return BaseOrderStateFnKey.DELIVER_WAITING;
    } else if (deliverState === DeliverStateEnum.DELIVER_STATE_IN) {
      /* 发货中 */
      return BaseOrderStateFnKey.DELIVER_IN;
    } else if (deliverState === DeliverStateEnum.DELIVER_STATE_TRANSIT) {
      /* 已发货 */
      return BaseOrderStateFnKey.DELIVER_TRANSIT;
    } else if (deliverState === DeliverStateEnum.DELIVER_STATE_FINISH) {
      /* 已发货 */
      return BaseOrderStateFnKey.DELIVER_COMPLETE;
    } else if (payState === OrderPayStateEnum.PAY_STATE_REFUNDING && deliverState === DeliverStateEnum.DELIVER_STATE_WAIT) {
      /* 退款中 */
      return BaseOrderStateFnKey.AFTER_SALES;
    } else if (payState === OrderPayStateEnum.PAY_STATE_REFUNDED && deliverState === DeliverStateEnum.DELIVER_STATE_WAIT) {
      /* 退款完成 */
      return BaseOrderStateFnKey.AFTER_SALES_COMPLETE;
    }
  } else if (orderState === OrderStateEnum.ORDER_STATE_CLOSE) {
    /* 已关闭 */
    return BaseOrderStateFnKey.ORDER_CLOSE;
  }
  return '' as BaseOrderStateFnKey;
}
