export type GetPromiseReturn<F extends (...args: any) => Promise<any>> = F extends (...args: any) => Promise<infer R> ? R : never;

// 支付类型
export enum PayTypeEnum {
  /** 微信支付 */
  PAY_TYPE_WECHAT = 'wechat',
  POINT_AND_WECHAT = 'point&wechat',
  POINT = 'point'
}

// 商品类型
export enum ProductTypeEnum {
  /* 实物商品 */
  PRODUCT_TYPE_PHYSICAL = '1',
  /* 虚拟商品 */
  PRODUCT_TYPE_VIRTUAL = '2'
}

// 订单支付状态
export enum OrderPayStateEnum {
  /** 待支付 */
  PAY_STATE_WAIT = 1,
  /** 已支付 */
  PAY_STATE_PAID = 2,
  /** 退款中 */
  PAY_STATE_REFUNDING = 3,
  /** 已退款 */
  PAY_STATE_REFUNDED = 4
}
// 订单状态
export enum OrderStateEnum {
  /* 已下单 */
  ORDER_STATE_CREATE = 1,
  /* 已完成 */
  ORDER_STATE_FINISH = 2,
  /* 已取消 */
  ORDER_STATE_CANCEL = 3,
  /* 已关闭 */
  ORDER_STATE_CLOSE = 4
}
// 发货状态
export enum DeliverStateEnum {
  /* 待发货 */
  DELIVER_STATE_WAIT = 1,
  /* 发货中 */
  DELIVER_STATE_IN = 2,
  /* 运输中 */
  DELIVER_STATE_TRANSIT = 3,
  /* 确认收货 */
  DELIVER_STATE_FINISH = 4
}

// 商品基础属性
export interface IBaseProduct {
  /** 虚拟商品输入框校验 */
  inputRules?: string;
  /** 所属分类id */
  catId: number;
  /** 创建时间 */
  createTime: string;
  /** 是否删除 [0/1 未删除/删除] */
  deleted: boolean;
  /** 商品图片地址 用来展示 默认图片 */
  imgUrl: string;
  /** 商品图片地址列表 */
  imgList: string[];
  /** 是否开启会员折扣 [0/1 不开启/开启] */
  memberDiscount: boolean;
  /** 商户id */
  merchantId: number;
  /** 直购券数量 默认不需要 */
  privilegeCoupon: number;
  /** 上架状态[false - 下架，true - 上架] */
  publishStatus: boolean;
  /** 起售数量 */
  saleMin: number;
  /** 开售时间 */
  saleTime: string;
  /** 商品描述 */
  spuDescription: string;
  /** 商品id */
  spuId: number;
  /** 商品名称 */
  spuName: string;
  /** 商品类型1实物商品 2 虚拟商品 */
  type: ProductTypeEnum;
  /** 更新时间 */
  updateTime: string;
  /** 重量 克 */
  weight: number;
  /** 最小价格 */
  minPrice: number;
  /** 最大价格 */
  maxPrice: number;
  /** 商品销量 */
  saleCount: number;
  /** 最小折扣价 */
  minDiscountPrice: number;
  /** 最大折扣价 */
  maxDiscountPrice: number;
  /** 商品折扣 */
  discount: number;
  /** 商品总库存 */
  inventory: number;
  /** 可用库存 */
  usableInventory: number;
  /** 商品类型 */
  spuType: ProductTypeEnum;
  /** 商品详情 */
  spuDecr: string;
}

// 规格 基础信息
export interface IBaseStand {
  /* 成本价 */
  costPrice: number;
  /* 创建时间 */
  createTime: string;
  /* 价格 分 */
  currentPrice: number;
  /* 是否启用 */
  enable: boolean;
  /* 商品库存 */
  inventory: number;
  /* 商品原价 划线价 */
  originPrice: number;
  /* 销量 */
  saleCount: number;
  /* sku介绍描述 */
  skuDesc: string;
  /* 规格id */
  skuId: number;
  /* 默认图片 */
  skuImg: string;
  /* sku名称 */
  skuName: string;
  /* 标题 */
  skuTitle: string;
  /* 商品id */
  spuId: IBaseProduct['spuId'];
  /* 更新时间 */
  updateTime: string;
  /* 剩余库存 */
  usableInventory: number;
  /** 会员价 */
  discountPrice?: number;
}

// 卡密
export interface IBaseCardPass {
  cardPwd?: string;
  cardNo?: string;
  expiredTime?: string;
  additionB?: string;
  additionA?: string;
}

// 基础订单
export interface IBaseOrder {
  /** 订单编号 */
  orderCode: string;
  /** 商品id */
  spuId: number;
  /** 商品名称 */
  spuName: string;
  /** 商品图片 */
  skuImgUrl: string;
  /** 商品规格id */
  skuId: number;
  /** 商品规格名称 */
  skuName: string;
  /** 商品数量 */
  commodityNum: number;
  /** 商品类型 1实物 2虚拟商品 */
  spuType: ProductTypeEnum;
  /** 订单现价 */
  currentPrice: number;
  /** 订单会员价 */
  discountPrice: number;
  /** 实付积分价格（由实际支付价格换算） */
  actualPoint: number;
  /** 实际支付价格 */
  actualPrice: number;
  /** 订单状态 1.已下单 2.已完成 3.已取消 */
  orderState: OrderStateEnum;
  /** 支付状态 1.待支付 2.已支付 3.退款中 4.退款完成 */
  payState: OrderPayStateEnum;
  /** 发货状态 1.待发货 2.发货中 3.已发货 4.确认收货 */
  deliverState: DeliverStateEnum;
  /** 下单时间 */
  orderTime: string;
  /** 支付时间 */
  payTime: string;
  /** 发货时间 */
  expressTime: string;
  /** 订单过期时间 */
  expireTime: string;
  /** 发货时间 */
  deliverTime: string;
  /** 结束时间（订单取消 订单完成） */
  endTime: string;
  /** 充值账号 */
  rechargeAccount: string;
  /** 卡密 */
  cardPassword: string;
  /** 支付类型，1：现金，2：积分 */
  payType: PayTypeEnum;
  /** 订单备注 */
  orderRemark: string;
  /** 收货信息: 姓名，电话，详细地址 */
  receiveAddress: string;
  /** 积分支付记录id */
  pointRecordId: string;
  /** 直购券支付记录id */
  buyCouponId: string;
  /** 直购券支付数量 */
  buyCouponNum: number;
  /** 商品详情 */
  spuDecr: string;
  /* 卡密列表 */
  cardList: IBaseCardPass[];
  /* 物流最后一条信息 */
  expressLastStatus?: string;
  /* 物流最后一条信息 */
  expressLastTime?: string;
  /* 扩展信息 */
  orderExtends: string;
}

// 购物车
export type IBaseShoppingCartItem = {
  /** 购物车id  */
  shoppingCartId: number;
  /** 商品id  */
  spuId: number;
  /** 规格id  */
  skuId: number;
  /** 加入购物车时的价格  */
  previousPrice: number;
  /** 数量  */
  amount: number;
  /** 商品名称  */
  spuName: string;
  /** 商品图片  */
  spuImgUrl: string;
  /** 规格图片  */
  skuImgUrl: string;
  /** 发布状态  */
  publishStatus: number;
  /** 规格名称  */
  skuName: string;
  /** 现价  */
  currentPrice: IBaseStand['currentPrice'];
  /** 原价  */
  originPrice: number;
  /** 最低价  */
  minPrice?: number;
  /** 最高价  */
  maxPrice?: number;
  /** 销量  */
  saleCount: number;
  /** 库存  */
  inventory: number;
  /** 创建时间  */
  createTime: string;
  /** 会员价 */
  discountPrice?: IBaseStand['discountPrice'];
};

// 基础 商品、规格销售信息
export interface IBaseAttr {
  /* 属性id */
  attrId: number;
  /* 属性名 */
  attrName: string;
  /* 属性类型[1-基本属性，2-销售属性] */
  attrType: string;
  /* 分类id */
  catId: number;
  /* 启用状态 */
  enable: boolean;
  /* 属性图标 */
  icon: string;
  /* 更新时间 */
  updateTime: string;
  /* 可选值列表[用逗号分隔] 这个作为在进行属性编辑时候默认字段方便快速选择 */
  valueSelect: string;
  /* 是否对属性进行校验 不设置则可随意输入 */
  verify: boolean;
  /* 顺序 */
  attrSort: number;
  /* 属性值 */
  attrValue: string;
  /* 创建时间 */
  createTime: string;
  /* id */
  id: number;
  /* 销售属性对应sku_id */
  skuId: IBaseStand['skuId'];
  /* 规格属性对应商品id */
  spuId: IBaseProduct['spuId'];
}

// 收藏商品属性
export interface IBaseCollect {
  /** 商品类别Id */
  catId: number;
  /** 收藏时间 */
  createTime: string;
  /** 收藏数 */
  favCount: number;
  /** 收藏Id */
  favoriteId: number;
  /** 商品价格 */
  previousPrice: number;
  /** 上架状态[0 - 下架，1 - 上架] */
  publishStatus: number;
  /** 商品Id */
  spuId: number;
  /** 商品图片 */
  spuImg: string;
  /** 商品名称 */
  spuName: string;
  /** 商品类型1实物商品 2 虚拟商品 */
  type: ProductTypeEnum;
}

// 卡券状态
export enum CouponStateEnum {
  // 0未使用 1已使用 2已作废 3已过期
  UNUSED = 0,
  USED = 1,
  VOIDED = 2,
  EXPIRE = 3
}

// 卡券类型
export enum CouponTypeEnum {
  // 1 折扣券 2 满减券 3 定向券
  DISCOUNT = 1,
  REDUCE = 2,
  DIRECT = 3
}

// 优惠券信息
export interface ICouponInfo {
  // 卡券ID
  couponId: number;
  // 券定义ID
  defineId: number;
  // 券批次ID
  batchId: number;
  // 卡券号码
  couponNumber: string;
  // 卡券密码
  couponPassword: string;
  // 生成时间
  createTime: string;
  // 过期时间
  expiredTime: string;
  // 卡券状态 0未使用1已使用2已作废
  couponState: CouponStateEnum;
  // 绑定用户
  bindUserId: string;
  // 绑定时间
  bindTime: string;
  // 核销金额
  consumePrice: number;
  // 核销时间
  consumeTime: string;
  // 核销用户
  consumeUserId: string;
  // 规则
  useRule: {
    // 优惠条件(作用于满减券,单位分) 即满{discountLimit}减{discountMax}
    discountLimit: number;
    // 优惠金额(单位分)
    discountMax: number;
    // 优惠类型 1 抵扣券 2 满减券 3 定向券
    discountType: CouponTypeEnum;
  };
}

// 积分明细及会员等级
export interface ICreditInfo {
  // 到期积分
  expiresCount: number;
  // 冻结积分
  freezeCount: number;
  // 回撤积分
  repealCount: number;
  // 累计获得积分
  sumCount: number;
  // 可用积分
  usableCount: number;
  // 已使用积分
  useCount: number;
}

export interface ICreditItem {
  changeCount: number;
  channel: string;
  channelCode: string;
  createTime: string;
  customerCode: string;
  pointId: number;
  recordId: string;
  // 1获取 / 2 使用 / 3 冻结 / 4 解冻 / 5 退还(获取积分后回撤) / 6 退款(积分支付后退款) / 7 转化消耗 / 8 转化获得 / 9 过期
  status: CreditType;
  title: string;
}

// 积分消耗类型
export enum CreditType {
  // 1获取 / 2 使用 / 3 冻结 / 4 解冻 / 5 退还(获取积分后回撤) / 6 退款(积分支付后退款) / 7 转化消耗 / 8 转化获得 / 9 过期
  GET = '1',
  USE = '2',
  FREEZE = '3',
  UNFREEZE = '4',
  RETURN = '5',
  REFUND = '6',
  CONSUMPTION = '7',
  CONVERSION = '8',
  PAST = '9'
}
