





















import { IDetailData } from '@/api/order';
import { ProductTypeEnum } from '@/api/type/base';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: ''
})
export default class extends Vue {
  @Prop({ required: true })
  innerData!: IDetailData;

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  get skuName() {
    return this.innerData.skuName.split(';');
  }

  get rechargeAccount() {
    const orderExtends = JSON.parse(this.innerData.orderExtends) as {
      spuInputRule: {
        /* 是否隐藏充值账号 */
        isHidden: boolean;
        /* 充值账号列表 */
        rules: Array<{
          type: string;
          name: string;
        }>;
      };
    };
    if (!orderExtends.spuInputRule) {
      return [`充值账号：${this.innerData.rechargeAccount || '无'}`];
    }

    if (orderExtends.spuInputRule.isHidden) {
      return [];
    }

    if (!this.innerData.rechargeAccount) {
      return [];
    }

    const accountList = this.innerData.rechargeAccount.split('=');

    return accountList.reduce((arr, item, index) => {
      arr.push(orderExtends.spuInputRule.rules[index].name + '：' + item);
      return arr;
    }, [] as string[]);
  }
}
