import Vue from 'vue';
import BaseDialogVue from './index.vue';
type BaseDialogOption = { title?: string; message?: string; onCancel?: () => void };
let instance: any;

const BaseDialogConstructor = Vue.extend(BaseDialogVue);
BaseDialogConstructor.prototype.close = function () {
  const dom = this.$el as HTMLElement;
  if (dom.parentNode) {
    dom.parentNode.removeChild(dom);
    instance.$destroy();
  }
};

/* 判断是否存在 */
function isInDocument(element: HTMLElement) {
  return document.body.contains(element);
}

function initInstance(data: BaseDialogOption, resolve: (value?: unknown) => void, reject: (reason?: any) => void) {
  instance = new BaseDialogConstructor({
    propsData: data,
    data: { resolve, reject },
    el: document.createElement('div')
  });
}

function BaseDialog(data: BaseDialogOption) {
  return new Promise((resolve, reject) => {
    if (!instance || !isInDocument(instance.$el)) {
      initInstance(data, resolve, reject);
    }
    document.body.appendChild(instance.$el);
  });
}

export default BaseDialog;
