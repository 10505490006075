












import { isEmpty } from 'lodash';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  show = false;

  @Prop()
  title!: string;

  @Prop({ default: '提示内容' })
  message!: string;

  @Prop({ default: true })
  showCancelButton!: boolean;

  @Prop({ type: Function, default: () => '' })
  onCancel!: () => void;

  @Prop({ type: Function, default: () => '' })
  onConfirm!: () => void;

  /* 关闭方法 */
  close!: () => void;
  /* promise回调 */
  resolve!: (value?: unknown) => void;
  reject!: (reason?: any) => void;

  @Emit()
  handleClickCancel() {
    this.onCancel();
    this.reject('cancel');
    this.show = false;
  }

  @Emit()
  handleClickConfirm() {
    this.resolve();
    this.onConfirm();
    this.show = false;
  }

  onClosed() {
    if (!isEmpty(this.close())) {
      this.close();
    }
  }

  mounted() {
    this.show = true;
  }
}
